export const orgIdsWithSidekick = [
  'sagan---',
  'clXA-2mhR-iQeQ0whSMA2A',
  'OHj53R93QjWo6TGyonNnww',
  'ZFppnT_8QkK1iaYMTlH4_A',
  'VJOTwOvcQ6WJr85bp0qL0g',
  'CbPddml6SkeJxWcXR7V3XQ',
  'FDBLngSJQqy2pmRANn8zJg',
  'DfNXxh6LReKlb4vhWUBlYw',
  'Kb4jsHUhQlOA1VcVNg29Jg',
  'CBpyCBr-SN-lIUQsN8L-mQ',
  'VpvvbagxRO-ntcdBPZ6yjw',
  'OgmK476SRXyvH2ymEd7-MQ',
  'CAm-jAjLTXe2pmohJu9MMg',
  'Patg5wo3QGaAtZLRIObezQ',
  'K_E_I8nmT0G_zTNvYbnFtQ',
  'MuCxCxU6QK-AsTjQOuaMxQ',
  'SaSrd4qGQUGqWFh_c2ARZg',
  'dx8NHi-USQy8h6Sozs_xvw',
  'O1kuCmPEQ7u0zRyhKF4EVA',
  'S_8nv7jeT0-M37jEoMClHQ',
  'XSPtINU8S_-24J-iiLSngw',
  'fxIYOGSUTd2U7feZ9QiNeg',
  'HxHmwVLnR-Srw3vIsXwnFQ',
  'Sxn1tk3zS6Wi0nf5cDycgw',
  'bVn9S7hVSRypQ1UqekoUYg',
  'WsC9W2ZZTQam0qVbfUw9IA',
  'I9Bol54-QROF59SHgkGmWg',
  'HGKRkyMTQrWK-xLuvTtl_Q',
  'H2CuN_QmRluVQxkyt2IDeg',
  'OcHLdncGQkqHDyIRUw00WA',
  'IaL374ygS-iFMAxBIYY0Ow',
  'Rn_90a4GRL-IIrUIIzmfwA',
  'bOPYuLDORQS_Ts0TGTCEng',
  'JldJ2WBgQXuVQUMFxvslFA',
  'H8Ae6N-IQi-qVcSQWFPfQA',
  'KM2awtksQcOj7G7gVMspLA',
  'UxsGnprBTYKSyDti1z8vng',
  'N5iAfI0bTg2x52W28Y0lkw',
  'R0_SXBvuTkmEPiolKQbjQA',
  'dAkiPo-CRlKsUFSlTPHdug',
  'L0Hk2YQ2RG6cvREfblyviQ',
  'BETX789ETCaf9hE54rIj9g',
  'AashFNpjSa-UUR62lq3Mag',
  'TALF-bf6QeOd8i6NMFAAIQ',
  'bn2Xey2fTpWnrM15RfLwfQ',
  'eLN-v-THTDGH2YJt4AXwvQ',
  'eQMQBSAUToet-RRrhrzWug',
  'D92YWKn_QAWGWmO2oC5pZw',
  'BzMoyVNnTYK7qcvRAmnLbA',
  'EbYEqWJNQ1GgPqGCK20hOQ',
  'RlgNlkESRTOmHqJeHA9fuA',
  'bGlGnPW4RIyYTuC9D0KBXQ',
  'PD3xYH5FS2S7hnszzowOGA',
  'cPe4y25zTlyJPc3eoDvShg',
  'aU3GwNw_ScKQpakXN8T5yg',
  'G5mMu6SAQD6b5mwQ5-ssIw',
];

export const orgsIdsWithoutSidekick = [
  'sagan---',
  'ASaRiSB7QdKkSUcSIG6nmQ',
  'cUWxpgswRviQ4m1irKPrKw',
  'EW1TQ9vdQQCZGISyy_TR-g',
  'VMjbB8YTReWjBfYTWZ6IHA',
  'SmMLCgmpTGybkpPIMvlgRg',
  'WuHmw8ekSuichqq6RDNisw',
  'doDlpqIeSlKvZmbYT6wjNQ',
  'R4y2FniCRA6a3B4frly3Gw',
  'F3JG2BsBRY-P_Va2kJQkSg',
  'XEIOb2t0S5ye58olsaf_og',
  'Njf-P8YzQMCACJjHk35DBQ',
  'HrYHRiScRPmqQK2NWAd75w',
  'aAGpMhy0QeGwOAyOwrDyZg',
  'Pq-T-rQGROmLXd38Hd7QYw',
  'RXfivkXPT0mFxyYSnbOwaw',
  'EwXAVc63QNexLrtkyDmYzw',
  'dq9zUvIQSkaU3EhDPEYPLw',
  'QLxcrU5hQo6GDo1LrvqdYA',
  'M0el_2rRTUucpJR4RdGgpQ',
  'ejD6VqWtRcWCyUUtCSC_nQ',
  'ANP100rXSVuzqxPyuwJDeA',
  'NFAlpNB0S0a3hSXx8Jzh8w',
  'eKWy6QUjSsmkAzhj2KNpuw',
  'GhNYo2p2TAeLr8tI_LuMAg',
  'A2wdrP_eTI6dV1rUoTc3Ng',
  'bDejiKYLTdizO8yW3hoNCA',
  'bJTsB_MpTE6cCEn8WnF74g',
  'W5Lha070SWCLB6uK4NR4BA',
  'OLfmgn7RQxyTCA0kpisoLw',
  'VeHAR3HxRXyDXhMJ4D15vg',
  'IDipBPauSeekbTMlw3rLpw',
  'c5BVWdv1Tjygg-OlJwaUXA',
  'O62DGqZVTaSnLl6iefblgg',
  'Xc0IudozTwSQATvJgOAUGA',
  'IrJt2ZOcR-KwgIQJQahHTQ',
  'OkF1PLrwSSG5eoETZVtF2g',
  'M9EAC-pIRmCrEqEQGu0O7A',
  'WBAhcmACTYazCxWwS4HSqw',
  'Skbf2CqJTyuUtzaiqJSOfw',
  'bvcaZGznSna-GcgSzBgZrA',
  'SnfJdOd7TtmRg-pkGgQEuQ',
  'CoMEwREqS8yARoQ31dY8DA',
  'W89ZHf4PQSer0GFZgrbamg',
  'bdToBSfLSkSCJZ-Ie4DjkQ',
  'XCm1yhRDTXuJ9YXi5x6SuQ',
  'cd0FhEDbSHuI5Y0Mu_3ZCA',
  'ahoCsWLOSX-Ls5Htgo3VaQ',
  'QxxZbWSJT4GfsEfGq372WA',
  'RoTZtjt7QCqhHb7U4kmsMA',
  'WpzlIk2LQoOzne-smvQaeg',
  'YOPeG_mWTdeOr9Yn6wzMCQ',
  '6OhkAurA1e2ArX5Gp405nA',
  'YBG1XfG2RpiUNP-anRPIXQ',
  'AscHlgcIR4iM9-66161cOA',
  'OpuyO_88QAyNaruI3D6UCg',
  'PGhHu8sNS4KuIV11rJU7dw',
  'GfW-IAzxSZuYzGzMVvRwpg',
  'BFmgoV4tQOy9esh2_4FeHQ',
  'S8w7Xsw4QwCAM0yzm8JcrA',
  'aKRyGPijTU2WWhsCUmdipw',
  'Kxab57hlSAStXtHhv-T3XQ',
  'GSQSfTI9T_mkpxp3E5qg4g',
  'DRBJDSuxS2u-TmMXaoAkaA',
  'BT-SOX5lSHSed6syKBXQ5w',
  'QlTHFhTzQe2ozAatKQC08Q',
  'fAo1SJ28SCGgSKeMLCrmCg',
  'ZkpQ7SY1QbeWMlNKwKiNtw',
  'Dk3rs0SrQyikz_M3VT4UoQ',
  'SkP7Oz23RyGDax1el6Q-Cg',
  'JpyN-sssQ9SDZtnXOyzbrg',
  'cM5MUxmRRUynH9Vk-wezWA',
  'LDxH7nX3QiSRgqtU07-6Mw',
  'doqx5j63T4e0_UbNPwPGpg',
  'Z24kMpH6SHuSawUPHt9R0A',
  'Sh_4TwQCTLaCG59o3zh19A',
  'Mf622-d6STOAftopAIijzA',
  'TQdXyanZS62OuM8JCiHl_A',
  'IHLXFw02RWak-Lnp_3Po3g',
  'R1yo_IUpS7u_DE0mOJpoLA',
  'SvJuKA8QQVimPbeFzJ2pwQ',
  'MdVIAZaQSa6tUMBK6J_zgw',
  'BqFFZMc1SmC9qdIVx4gCuQ',
  'DxTG1uQxTSav5iD2sXtzLQ',
  'EkJZauLNT0Gisy8HPmy-xw',
  'SSqIPqxnTjSpUax5xzddbw',
  'dobU8x2GQFOnUzH3vJTSUA',
  'SbXpDcMARUiCHo46Gfy7HA',
  'EEU7SKvxQEa_Wm7R3zcKeA',
  'T4BJ3v2LRX6PSNUSJOVimw',
  'DOj93D-hSSKzqBq03SzYBw',
  'UOzz2PlwSd2IwF_aEOHYkQ',
  'YDmE8YVUT4uA3pm3U9-Dyw',
  'R8BENhJzROSdQrHpNWwaJQ',
  'd4fE62hCSz6r0jyMB3ka7w',
  'aYW79rETQ6K_v_IRRixakQ',
  'PptU_GR-QOyhyZJrqbJvfA',
  'DitwLarKQPaZ0s4yueRdLQ',
  'Nj2j7L55RGG4Sg4i79yLsw',
  'Xn2Da_m8QAGdHSc0oW5z7g',
  'bIlaDXiVQa2YvvdigbqMhg',
  'CrApdCT2RiK5EJ3z2ea8vA',
  'HFnh5RL-RiiI8VM0WpcWcA',
  'es474OyBTZy_a_Msn0sCdw',
  'XAqgHllzT_iuK4SnXBQ-Zw',
  'e9wSdqLnSlmOxCzXArOa4w',
  'fN5qMgP2TYCPO4Z4-3z1YA',
  'aPQCeboWQCaBt76zNHAEZQ',
  'GDQReNlDQjaFm6uFG5j4lQ',
  'FCLghTbpTeqQpwzzmjx39A',
  'PYHy7ebRRUSgUbWZouxUfA',
  'H-eH7wqWT3CPfiQ83UY3ug',
  'W6UEgPylSGyT-XJeLH674w',
  'fQ0tSFA2RcqM7_ematvRYQ',
  'TOdIj6NCQamILU7HfvEHTQ',
  'HcawIbURRGmcjfYpKLGAnw',
  'ERc2cLm2R0ekjFoz5CFZAA',
  'B-9N9IH7St-Fy6n2hDfTUg',
  'PqK0nwMKRV2TnOqQFpQfhw',
  'JzXnaXWtSb2eIs4HgK6tKw',
  'AmAEdN23SaevF7dfiXiKhQ',
  'Ar92d0BvT-OlyCbMsGv3YA',
  'VeI6AVuYS_-D1Ijxquvf9A',
  'JX3zbULyR7SRriH0VYiIqw',
  'BAY2KqySQzWGnw548Cx__A',
  'Igx1k-nYRGumHg-XDM-aeg',
  'TBJ1JeCMQUSb9oy7getitQ',
  'DgD8bYFvQf2HF_54z7vt6g',
  'Fz1X5xmeQpqrGe8niYoVqA',
  'coy3Ex7CSIi1EBf3bWOmPA',
  'bks53l4GQu6ZiUpGz35vAg',
  'eFKtB3pUQymAPglMcRjjNA',
  'IJR2c_TAScSJV0wIqJ7kug',
  'Knc-NrIbQE-kQ1hADNPA5g',
  'TNOWttwhSxi17cNOIF-fwQ',
  'Utr9PPShShemf8QHwyeHhw',
  'PW0bR_jeQnKMag746aEdWA',
  'P0Vfl5wPRg6wahlw0mEx4w',
  'K1sRIFgGTxSq7WOIGpBOsw',
  'bT8adO8HTGi61buzhIRvoA',
  'GyGeiJuNRh20pBLHrE9dSQ',
  'Jii3JgOqRdO7VVtph4HNaw',
  'VN7KFKJASkiexrre0WLOSg',
  'Hp001xZIQXWpE-y9l4hG-A',
  'N74Esjt1TrCCavOrXgeZIA',
  'BrEpPIVFR9OLCMm_bj3kug',
  'SIWbwBmHQPmGf2E-qlmELw',
  'LRqEg5ioRqW1PAP_uWxG5g',
  'Zk2j9UYwTpmqBDCs22qwZg',
  'Pa0jcE4-QPebadF4_wzEow',
  'M_s2djtORDymZRykcXzkgg',
  'SSQR9KimR06S4hSNOu6_nQ',
  'PRpUlRuHSyShDEnfKMONqA',
  'Dn9x_mXNTi27F5exCMGUVA',
  'EhvNHbl_QW2xWQl2oLJPOg',
  'DNvcuy2IS9yay3QGMIgtdQ',
  'TOzsJf8lRJ-3eJAI9ZfQdA',
  'EamN9pPrQ6e1vTq8QYmKRg',
  'AAbpgKm-S5WvtjpYSUnl9w',
  'NEQSg-CyTKCoz0HkHjapYA',
  'chQcMIaMR0COtLuqmP_liw',
  'FdgQjXjETFO9pTtWfKpK_A',
  'HyNIurcESq6GXcnfWcRIQQ',
  'bz13djhrR-uEzUfsiOFiuw',
  'b9NEtpywSHmDIxVjWjO5Jw',
  'MBVjWEmzQtqwQXfOexCq9Q',
  'G0VfPSKXTx2jL16SW7BqCg',
  'K02NE3psQ_e_hI-EUH9m9g',
  'K6hAAOupSfGNChNPT7rVvw',
  'G-O7P9KoTgupWpdj4Ck6UA',
  'Xsk7bxx9Qt6_ymAQoiNutw',
  'ZYbD5Gu_T2qvZau9ovIyyw',
  'AB53Y8r4Ti23D2DYnx4h7g',
  'N1i-8DquRveQf9lhNYKcqQ',
  'CwgHCwIPAgMMCA4IBAUBDQ',
  'DG1QLN_YS8iXs12LLqxUVw',
  'YqQL4HsoQoWNLkqfrGT7zA',
  'VbMpPoHJSTK0zePPs_Fr9g',
  'K2yszRKwRcChtNBCBjpjNA',
  'WjZOC2ASTR66_0YjHdGKiw',
  'BrJbzemvQg6YkiDohWFrlw',
  'ScQavPK6TLOwpj-TH-Z9Dw',
  'MQE5-tB_QwGRZeSfdBsCdg',
  'SKmsfDTDRBmA_0LgtZ-mFw',
  'bgrS2X4lShS7ua5F3sbN_g',
  'exqSs2c8RoGkoYRWgy6Z-A',
  '1kYWgUZemac22ijcwy63Jw',
  'aAOpcQxHR16HuwSDvZp0sg',
  'Mf2KmKR5TXG5wa2YSljRAQ',
  'bxFmmGF4Soyl4SDN1veT2g',
  'Jqh1K8Z7Q62S76gvIrDLlg',
  'AFrCY8h0S5C1EKNii0OvzA',
  'apdPdVSqSgSnpL5RlcJ8nQ',
  'Bq5O4FGeR6CHGQtaizG6gA',
  'JfjK72v9ToWVOiIV3BK_jA',
  'Ytd9WjguQkWHOm0JwcTPig',
];
